<template>
  <div class="flex flex-column align-items-center full-width">
    <div class="flex align-items-center full-width">
      <h3 class="temp-title negative-text" >{{ card.display_name }}</h3>
    </div>
    <span class="small-text grey">Owner: {{ name }} (100%) </span>
    <br />
    <div class="provider-logo flex-none flex align-items-center justify-content-center" >
      <img :src="image" :alt="card.provider.provider_id" style="max-width: 120px; height: 40px;">
    </div>
    <br />
    <div class="bank-card">
      <div class="flex">
        <div class="flex-1 flex align-items-center justify-content-start special-heading-2 white">{{ card.card_type }} Card</div>
        <div class="flex-none flex align-items-center justify-content-end"><img :src="image" :alt="card.provider.provider_id" style="max-width: 90px; height: 30px; filter: brightness(0) invert(1);"></div>
      </div>
      <div class="flex">
        <div class="card-chip card-text"></div>
      </div>
      <div class="flex">
        <div class="flex-1 flex align-items-center justify-content-start special-heading-1 white card-text">**** **** **** {{ card.partial_card_number }}</div>
      </div>
      <div class="flex">
        <div class="flex-1 flex align-items-center justify-content-start special-heading-2 white card-text">{{ card.name_on_card }}</div>
      </div>
      <div class="flex">
        <div class="flex-1 flex align-items-center justify-content-start special-heading-2 white card-text">{{ card.valid_from }} - {{ card.valid_to }}</div>
        <div class="flex-none flex align-items-center justify-content-end"><div class="network-logo" :class="networkClass" ></div></div>
      </div>
    </div>
    <spacer-h />
    <h1 class="blue text-center negative-text" ><currency-value :value="-card.value" :decimal-places="0" /></h1>
    <p class="small-text text-center grey">Value as at {{ presentDate.format('DD-MM-YYYY') }} </p>
    <open-banking-actions :account="card" />
  </div>
</template>
<script>

import CurrencyValue from "@/components/common/CurrencyValue";
import SpacerH from '@/components/common/layout/SpacerH.vue'
import {computed} from "vue";
import dayjs from "dayjs";
import {useStore} from "vuex";
import OpenBankingActions from "@/components/open_banking/OpenBankingActions.vue";

export default {
  name: "OpenBankingCard",
  components: {
    OpenBankingActions,
    CurrencyValue,
    SpacerH,
  },
  props: {
    card: Object
  },
  setup(props) {

    const store = useStore()

    const presentDate = computed(() => {
      return new dayjs()
    })

    const image = computed(() => store.getters.providerImageForID(props.card.provider.provider_id))

    const networkClass = computed( () => {
      switch(props.card.card_network) {
        case 'MASTERCARD':
          return 'mastercard';
        case 'VISA':
          return 'visa';
        case 'AMEX':
          return 'amex';
        default:
          return '';
      }
    })

    const name = computed(() => store.getters.getClientName)

    return {
      presentDate,
      networkClass,
      image,
      name
    }
  }
}
</script>
<style scoped>
  .temp-title {
    display: flex;
    text-align: center;
    color: var(--clarity-blue);
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    inline-size: 100%;
    height: 60px;
  }
  .negative-text {
    color: var(--clarity-red);
  }
  .bank-card {
    padding: 18px;
    width: 80%;
    background-image: url('../../assets/img/openbanking/Card-Box.svg');
    background-size: cover;
    border-radius: 12px;
  }
  .card-chip {
    width: 40px;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: url('../../assets/img/openbanking/Chip.svg');
  }
  .card-text {
    margin-top: 4px;
  }
  .network-logo {
    width: 65px;
    height: 35px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain, cover;
  }
  .network-logo.visa {
    background-image: url('../../assets/img/openbanking/VISA-Logo.svg');
  }
  .network-logo.mastercard{
    background-image: url('../../assets/img/openbanking/Mastercard-Logo.svg');
  }
  .network-logo.amex {
    background-image: url('../../assets/img/openbanking/AMEX-Logo.svg');
  }
</style>
