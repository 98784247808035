<template>
  <div class="open-banking p-5 flex flex-column">
    <div>
      <content-card-full>
        <template #title>
          <card-title>
            <template #title><h2>Open Banking</h2></template>
          </card-title>
        </template>
        <template #content-full>
          <div v-if="!scrollable">
            <div class="grid" v-if="loading">
              <div class="col-12 lg:col-6 xl:col-4">
                <Skeleton height="40rem" borderRadius="16px"></Skeleton>
              </div>
              <div class="col-12 lg:col-6 xl:col-4">
                <Skeleton height="40rem" borderRadius="16px"></Skeleton>
              </div>
              <div class="col-12 lg:col-6 xl:col-4">
                <Skeleton height="40rem" borderRadius="16px"></Skeleton>
              </div>
            </div>
            <div class="grid" v-else>
              <div class="col-12 lg:col-6 xl:col-4" v-if="code || renewCode">
                <inner-card class="add-more justify-content-center" colourClass="grey">
                  <template #content>
                    <div class="flex flex-column" style="height: 100%">
                      <div class="flex align-items-center justify-content-center ">
                        <ProgressSpinner style="width:30px;height:30px" strokeWidth="8" />
                      </div>
                      <spacer-h />
                      <div class="flex align-items-center justify-content-center ">
                        <p class="body-text">Requesting account information</p>
                      </div>
                      <div class="flex align-items-center justify-content-center ">
                        <span class="small-text grey">Please wait...</span>
                      </div>
                    </div>
                  </template>
                </inner-card>
              </div>
              <div v-for="account in accounts" :key="account" class="col-12 lg:col-6 xl:col-4">
                <inner-card :class="{ expired: isExpired(account) }">
                  <template #content>
                    <div v-if="account.type === 'ACCOUNT'">
                      <open-banking-account :account="account" />
                    </div>
                    <div v-else-if="account.type === 'CARD'">
                      <open-banking-card :card="account" />
                    </div>
                  </template>
                </inner-card>
              </div>
              <div class="col-12 lg:col-6 xl:col-4" v-if="(!code && !scrollable)">
                <inner-card class="add-more justify-content-center" colourClass="grey" style="min-height:450px">
                  <template #content>
                    <div class="flex flex-column" style="height: 100%">
                      <div class="flex align-items-center justify-content-center ">
                        <p class="body-text">Need to add more items?</p>
                        <spacer-h />
                      </div>
                      <div class="flex align-items-center justify-content-center ">
                        <a :href="authLink"><Button label="Add Bank Account or Credit Card" class="clarity-gradient-orange clarity-btn" /></a>
                      </div>
                    </div>
                  </template>
                </inner-card>
              </div>
            </div>
          </div>
          <div v-else>
            <Carousel :value="carouselAccounts" :numVisible="3" :numScroll="3" :responsiveOptions="responsiveOptions">
              <template #item="slotProps">
                <div class="m-3" style="height: 95%">
                  <inner-card style="min-height:450px" :class="{ expired: isExpired(slotProps.data) }">
                    <template #content>
                      <div v-if="slotProps.data.type === 'ACCOUNT'">
                        <open-banking-account :account="slotProps.data" />
                      </div>
                      <div v-else-if="slotProps.data.type === 'CARD'">
                        <open-banking-card :card="slotProps.data" />
                      </div>
                      <div v-else-if="slotProps.data.type === 'LOADING'">
                        <Skeleton height="40rem" borderRadius="16px"></Skeleton>
                      </div>
                    </template>
                  </inner-card>
                </div>
              </template>
            </Carousel>
          </div>
          <div style="clear: both;"></div>
          <div v-if="(!code && scrollable)" >
            <div class="pt-5" align="center">
              <a :href="authLink"><Button label="Add Bank Account or Credit Card" class="clarity-gradient-orange clarity-btn" /></a>
            </div>
          </div>
          <div class="mt-3">
            <h3 class="dark-grey" >DISCLAIMER</h3>
            <div>
              <a href="https://truelayer.com/enduser_tos" target="_blank"><div class="body-text flex align-content-center	mt-2"><span class="material-icons-round dark-grey mr-3">launch</span> TrueLayer Terms of Service</div></a>
              <a href="https://truelayer.com/privacy" target="_blank"><div class="body-text flex align-content-center	mt-2"><span class="material-icons-round dark-grey mr-3">launch</span> TrueLayer Privacy Policy</div></a>
            </div>
          </div>
        </template>
      </content-card-full>
    </div>
  </div>
</template>
<script>
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import CardTitle from '@/components/common/CardTitle.vue'
import InnerCard from '@/components/common/InnerCard'
import { authentication_link } from "@/core/services/openBanking";
import OpenBankingAccount from "@/components/open_banking/OpenBankingAccount";
import OpenBankingCard from "@/components/open_banking/OpenBankingCard";
import SpacerH from "@/components/common/layout/SpacerH";


import {computed, onMounted, ref } from 'vue';
import {useStore} from 'vuex';
import dayjs from "dayjs";

import { useRoute } from 'vue-router'

export default {
  name: "OpenBankingContent",
  props: {
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  setup() {

    const route = useRoute()
    const store = useStore()
    const authLink = authentication_link;

    const responsiveOptions = ref([
      {
        breakpoint: '1366px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '1024px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '700px',
        numVisible: 1,
        numScroll: 1
      }
    ]);

    if(route.query.code && route.path == '/investments/openbanking'){
      store.commit('setOBAccountsAdding', true)
      store.dispatch('submitCode', route.query.code)
    }else if(route.query.code && route.path == '/investments/openbankingrenew') {
      store.commit('setOBAccountsRenewing', true)
      store.dispatch('renewCode', route.query.code)
    }
    const code = computed(() => store.state.openbanking.obAccountsAdding)
    const renewCode = computed(() => store.state.openbanking.obAccountsRenewing)

    const accounts = computed(() => store.state.openbanking.obAccounts)
    const providers = computed(() => store.state.openbanking.obProviders)
    const loading = computed(() => store.state.openbanking.obAccountsLoading)

    const carouselAccounts = computed(() => {
      if(loading.value){
        return [
          { type: "LOADING" },
          { type: "LOADING" },
          { type: "LOADING" }
        ]
      }else{
        return accounts.value
      }
    })

    // on mount ensure the data feeds call is made!
    onMounted(() => {
      // dispatch the action to load the data feeds.
      store.dispatch('loadOBAccounts')
      store.dispatch('loadOBProviders')
    })

    const presentDate = computed(() => {
      const date = store.state.valuation.valuationDate;
      return new dayjs(date)
    })

    const isExpired = (account) => {
      return calculateDaysFromToday(account['consent_expires']) < 0
    }

    const calculateDaysFromToday = (date) => {
      const today = new Date();
      const givenDate = new Date(date);

      // Calculate the difference in milliseconds
      const diffInMillis = givenDate - today;

      // Convert milliseconds to days
      const diffInDays = Math.round(diffInMillis / (1000 * 60 * 60 * 24));

      return diffInDays;
    }

    return {
      accounts,
      providers,
      presentDate,
      loading,
      code,
      renewCode,
      authLink,
      responsiveOptions,
      carouselAccounts,
      isExpired
    }
  },
  components: {
    SpacerH,
    OpenBankingCard,
    OpenBankingAccount,
    ContentCardFull,
    CardTitle,
    InnerCard
  }
}
</script>
<style scoped>
  .scrollable {
    overflow-y:scroll;
    max-height:580px;
    overflow-x: hidden;
    padding-right: 8px;
  }
</style>
