<template>
  <div class="flex flex-column align-items-center full-width" style="height: 100%">
    <div class="flex align-items-center full-width">
      <h3 class="temp-title" >{{ account.display_name }}</h3>
    </div>
    <span class="small-text grey">Owner: {{ name }} (100%) </span>
    <br />
    <div class="provider-logo flex-none flex align-items-center justify-content-center" >
      <img :src="image" :alt="account.provider.provider_id" style="max-width: 120px; height: 40px;">
    </div>
    <br />
    <h4 class="dark-grey text-center bold uncapitalize">IBAN:</h4>
    <h4 class="light-blue text-center uncapitalize">&nbsp;{{ account.account_number.iban }}</h4>
    <br />
    <h4 class="dark-grey text-center bold uncapitalize">Number:</h4>
    <h4 class="light-blue text-center uncapitalize">&nbsp;{{ account.account_number.number }}</h4>
    <br />
    <h4 class="dark-grey text-center bold uncapitalize">Sort Code:</h4>
    <h4 class="light-blue text-center uncapitalize">&nbsp;{{ account.account_number.sort_code }}</h4>
    <spacer-h />
    <h1 class="blue text-center" :class="{ 'negative-text': (account.value < 0) }"><currency-value :value="account.value" :decimal-places="0" /></h1>
    <p class="small-text text-center grey">Value as at {{ presentDate.format('DD-MM-YYYY') }}</p>
    <open-banking-actions :account="account" />
  </div>
</template>
<script>

import CurrencyValue from "@/components/common/CurrencyValue";
import SpacerH from '@/components/common/layout/SpacerH.vue'
import {computed} from "vue";
import dayjs from "dayjs";
import {useStore} from "vuex";
import OpenBankingActions from "@/components/open_banking/OpenBankingActions.vue";

export default {
  name: "OpenBankingAccount",
  components: {
    OpenBankingActions,
    CurrencyValue,
    SpacerH,
  },
  props: {
    account: Object
  },
  setup(props) {

    const store = useStore()

    const presentDate = computed(() => {
      return new dayjs()
    })

    const image = computed(() => store.getters.providerImageForID(props.account.provider.provider_id))
    const name = computed(() => store.getters.getClientName)

    return {
      presentDate,
      image,
      name
    }
  }
}
</script>
<style scoped>
  .temp-title {
    display: flex;
    text-align: center;
    color: var(--clarity-blue);
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    inline-size: 100%;
    height: 60px;
  }
  .negative-text {
    color: var(--clarity-red);
  }
</style>
